<template>
  <div>
    <el-main>
      <search-work-order @submit="submitSearch"/>

      <div class="table-btn-list">
        <el-button
            class="table-btn"
            type="primary"
            @click="showNewModal"
        >
          新增
        </el-button>
      </div>

      <el-table v-loading="loading" :data="workOrderList">
        <el-table-column width="100">
          <template #default="scope">
            <div style="display:flex;align-items:center;justify-content:center">
              <el-image style="width: 52px; height: 52px;" :src="backImgUrl" fit="contain"/>
              <el-image style="width: 23px; height: 22px;position:absolute" :src="imgUrl" fit="contain"/>
            </div>
            <div style="display:flex;align-items:center;justify-content:center;margin-top: 5px">
              <dict-tag-dark :options="dict.type.work_order_status"
                             :value="scope.row.status"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-descriptions :column="4" :title="scope.row.name">
              <template #extra>
                <el-tooltip content="操作历史">
                  <el-icon :size="20" color="#1396db" style="margin-right:10px"
                           @click="showOperationHistory(scope.row.id)">
                    <Clock/>
                  </el-icon>
                </el-tooltip>

                <el-tooltip content="编辑"
                            v-if="scope.row.status === selectDictValue(dict.type.work_order_status, 'Draft')">
                  <el-icon :size="20" color="#1396db" style="margin-right:10px" @click="showEditModal(scope.row)">
                    <Edit/>
                  </el-icon>
                </el-tooltip>

                <el-tooltip content="提交"
                            v-if="scope.row.status === selectDictValue(dict.type.work_order_status, 'Draft')">
                  <el-icon :size="20" color="#1396db" style="margin-right:10px"
                           @click="submitDeviceLocation(scope.row.id)">
                    <CircleCheck/>
                  </el-icon>
                </el-tooltip>

                <el-tooltip content="删除">
                  <el-icon :size="20" color="#ff0000" @click="handleDelete(scope.row)">
                    <Delete/>
                  </el-icon>
                </el-tooltip>
              </template>

              <el-descriptions-item label="工单编号：" width="100">{{ scope.row.workOrderNo }}</el-descriptions-item>
              <el-descriptions-item label="设备编号：" width="100">{{ scope.row.deviceNo }}</el-descriptions-item>
              <el-descriptions-item label="设备类型：" width="100">{{ selectDictLabel(dict.type.device_type, scope.row.deviceLocation?.deviceType) }}</el-descriptions-item>
              <el-descriptions-item label="项目名称：" width="100">{{ scope.row.deviceLocation?.project?.name }}</el-descriptions-item>

              <el-descriptions-item label="故障现象：" width="100">
                <a
                    v-if="scope.row.workOrderFaultDescriptionList && scope.row.workOrderFaultDescriptionList.length > 0"
                    @click="showFaultAndProcessResultModal(scope.row)"
                    style="color: #409eff"
                >
                  {{ selectDictLabel(dict.type.work_order_fault_phenomenon_type, scope.row.workOrderFaultDescriptionList[0].faultPhenomenonType) }}
                </a>
              </el-descriptions-item>
              <el-descriptions-item label="工单来源：">
                {{ selectDictLabel(dict.type.work_order_source, scope.row.source) }}
              </el-descriptions-item>
              <el-descriptions-item label="设备名称：">{{ scope.row.deviceLocation?.deviceName }}</el-descriptions-item>
              <el-descriptions-item label="项目类型：">{{ scope.row.deviceLocation?.project?.projectType?.name }}</el-descriptions-item>

              <el-descriptions-item label="创建时间：">{{ scope.row.createTime}}</el-descriptions-item>
              <el-descriptions-item label="工单负责人：">{{ scope.row.userName }}</el-descriptions-item>
            </el-descriptions>
          </template>
        </el-table-column>
      </el-table>

      <pagination
          v-show="total>0"
          :total="total"
          v-model:page="query.pageNum"
          v-model:limit="query.pageSize"
          @pagination="fetchData"
      />

      <!-- 新增 模态框 -->
      <new-work-order
          v-if="isShowNewModal"
          v-model:visible="isShowNewModal"
          @completed="fetchData"
      />

      <!-- 编辑 模态框 -->
      <edit-work-order
          v-if="isShowEditModal"
          :id="editingId"
          v-model:visible="isShowEditModal"
          @completed="fetchData"
      />

      <operation-history-list-modal
          v-if="isShowOperationHistoryModal"
          :reference-id="showingOperationHistoryId"
          reference-type="WorkOrder"
          v-model:visible="isShowOperationHistoryModal"
      />

      <!-- 故障现象和处理结果 模态框 -->
      <fault-and-process-result
          v-if="isShowFaultAndProcessResultModal"
          v-model:visible="isShowFaultAndProcessResultModal"
          :fault-list="showingFaultList"
          :is-show-process-list="isShowProcessResult"
      />
    </el-main>

  </div>
</template>

<script>
import {isResOK} from '@/api/response'
import {defineAsyncComponent} from 'vue'
import SearchWorkOrder from '@/view/work_order/Search.vue'
import Pagination from "@/components/Pagination/index.vue";
import {selectDictLabel, selectDictValue} from "@/utils/ruoyi";
import OperationHistoryListModal from "@/view/operation_history/Modal.vue";
import DictTag from "@/components/DictTag/index.vue";
import {delWorkOrder, listWorkOrder, submitWorkOrder} from "@/api/work_order";
import store from "@/store";
import {getUrlParams} from "@/api/userList";
import {getCurrentUser} from "@/api/user";


let baseURL = process.env.VUE_APP_BASE_API
export default {
  name: 'WorkOrderList',
  dicts: ['work_order_status', 'work_order_fault_phenomenon_type', 'work_order_source', 'device_type'],
  components: {
    DictTag,
    Pagination,
    SearchWorkOrder,
    NewWorkOrder: defineAsyncComponent(() => import('@/view/work_order/New.vue')),
    EditWorkOrder: defineAsyncComponent(() => import('@/view/work_order/Edit.vue')),
    DictTagDark: defineAsyncComponent(() => import('@/components/DictTag/DictTagDark.vue')),
    OperationHistoryListModal,
    FaultAndProcessResult: defineAsyncComponent(() => import('@/view/work_order/FaultAndProcess.vue')),
  },

  data() {
    return {
      data: [],
      loading: true,
      total: 0,

      query: {
        pageNum: 1,
        pageSize: 10,
      },

      sort: {
        sort_field: '',
        sort_order: '',
      },

      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowOperationHistoryModal: false, // 是否显示操作历史弹窗
      isShowFaultAndProcessResultModal: false, // 是否显示操作历史弹窗
      editingId: 0, // 正在编辑的记录id
      detailId: 0,
      workOrderList: [],
      imgUrl: require(`/src/assets/work_order.png`),
      backImgUrl: require(`/src/assets/plan_back.svg`),
      imageUrl: '',
      showImage: false,
      showingOperationHistoryId: 0,
      showingFaultList: [], // 正在展示的现象列表
      isShowProcessResult: false, // 是否展示处理结果
    }
  },

  computed: {},

  created() {
    this.fetchData()
    this.fetchCurrentUser()
  },

  methods: {
    selectDictLabel,
    selectDictValue,
    fetchCurrentUser() {
      store.dispatch('user_info/cleanUser')
      let data = {}
      const params = getUrlParams(window.location.href)
      if (params[`accessToken`]) {
        sessionStorage.setItem('token', params[`accessToken`])
        data[`auth-token`] = params[`accessToken`]
        getCurrentUser(data).then(res => {
          if (isResOK(res)) {
            store.dispatch('user_info/setUser', res.data)
            console.log(store.getters.user)
          }
        })
      }
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(row) {
      this.editingId = row.id
      this.isShowEditModal = true
    },

    showOperationHistory(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },

    showFaultAndProcessResultModal(row) {
      this.showingFaultList = row.workOrderFaultDescriptionList
      this.isShowProcessResult = row.status === this.selectDictValue(this.dict.type.work_order_status, 'Processed')
      this.isShowFaultAndProcessResultModal = true
    },


    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
          {pageNum: this.query.pageNum },
          search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.workOrderList = []
      listWorkOrder(this.query).then(response => {
        this.workOrderList = response.rows
        this.total = response.total
        this.loading = false;
      }).finally(() => {
        this.loading = false
      })
    },

    submitDeviceLocation(id) {
      this.$messageBox.confirm(
          '确定进行提交吗？提交后无法修改',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        let operatorId = ''
        let operatorName = ''
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo !== undefined){
          operatorId = userInfo.id
          operatorName = userInfo.name
        }
        submitWorkOrder(id, { operatorId: operatorId, operatorName: operatorName }).then(res => {
          if (isResOK(res)) {
            this.$message({
              message: '提交成功',
              type: 'success',
            })
          }
          this.fetchData()
        })
      })
    },

    handleDelete(row) {
      const id = row.id
      this.$messageBox.confirm(
          '确定删除吗？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        delWorkOrder(id, { operatingMode: 'web' }).then((res) => {
          if (isResOK(res)) {
            this.$message({
              message: '数据删除成功',
              type: 'success',
            })
          }
          this.fetchData()
        })
      }).catch(() => {
      })
    },

  },
}
</script>
