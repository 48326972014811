<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="85px" label-position="left">
      <el-row>
        <el-col
            :span="6"
        >
          <el-form-item label="工单名称" prop="name">
            <el-input
                style="width: 240px"
                v-model="queryParams.name"
                placeholder="请输入工单名称"
                clearable
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
        </el-col>
        <el-col
            :span="6"
        >
          <el-form-item label="设备编号" prop="deviceNo">
            <el-input
                style="width: 240px"
                v-model="queryParams.deviceNo"
                placeholder="请输入设备编号"
                clearable
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
        </el-col>
        <el-col
            :span="6"
        >
          <el-form-item label="设备名称" prop="deviceName">
            <el-input
                style="width: 240px"
                v-model="queryParams.deviceName"
                placeholder="请输入设备名称"
                clearable
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
        </el-col>
        <el-col
            :span="6"
        >
          <el-form-item label="设备类型" prop="deviceType">
            <el-select style="width: 240px" v-model="queryParams.deviceType" :clearable="true"
                       placeholder="请选择设备类型">
              <el-option
                  v-for="dict in dict.type.device_type"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
            :span="6"
        >
          <el-form-item label="项目名称" prop="projectName">
            <el-input
                style="width: 240px"
                v-model="queryParams.projectName"
                placeholder="请输入项目名称"
                clearable
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
        </el-col>
        <el-col
            :span="6"
        >
          <el-form-item label="项目类型" prop="projectType">
            <el-select style="width: 240px" v-model="queryParams.projectTypeId" :clearable="true"
                       placeholder="请选择项目类型" @focus="getProductTypeOptions">
              <el-option
                  v-for="item in productTypeOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
            :span="6"
        >
          <el-form-item label="状态" prop="status">
            <el-select style="width: 240px" v-model="queryParams.status" :clearable="true" placeholder="请选择状态">
              <el-option
                  v-for="dict in dict.type.work_order_status"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
            :span="6"
        >
          <el-form-item label="工单负责人" prop="user_id">
            <el-select
                style="width: 240px"
                v-model="queryParams.userId"
                :clearable="true"
                placeholder="请选择状态"
                @focus="fetchUserListOptions"
                filterable
            >
              <el-option
                  v-for="item in userListOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
            :span="6"
        >
          <el-form-item label="创建时间" prop="create_time">
            <el-date-picker
                style="width: 240px"
                v-model="queryParams.queryTime"
                type="date"
                placeholder="请选择创建日期"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
        <el-col
            :span="6"
        >
          <el-form-item label="工单来源" prop="source">
            <el-select style="width: 240px" v-model="queryParams.source" :clearable="true" placeholder="请选择工单来源">
              <el-option
                  v-for="dict in dict.type.work_order_source"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
            :span="6"
        >
          <el-form-item>
            <el-button type="primary" @click="handleQuery">搜索</el-button>
            <el-button type="warning" @click="resetQuery">刷新</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {getProjectTypeOptions} from "@/api/project_type";
import {isResOK, isSysResOK} from "@/api/response";
import {queryOrgRelTree} from "@/api/system";
import {areaOptions} from "@/api/mock";
import {findUserList} from "@/api/userList";

export default {
  name: 'SearchWorkOrder',
  emits: ['submit'],
  dicts: ['device_type', 'work_order_status', 'work_order_source'],

  data() {
    return {
      queryParams: {
        deviceNo: null,
        name: null,
        deviceName: null,
        deviceType: null,
        projectName: null,
        projectTypeId: null,
        status: null,
        user_id: null,
        createTime: null,
        source: null,
      },
      productTypeOptions: [],
      areaOptions: [],
      userListOptions: [],
    }
  },

  created() {
    this.getProductTypeOptions()
  },

  methods: {
    fetchUserListOptions() {
      findUserList().then(res => {
        this.userListOptions = res
      })
    },

    getProductTypeOptions() {
      getProjectTypeOptions().then(res => {
        if (isResOK(res)) {
          this.productTypeOptions = res.data
        }
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.$emit('submit', this.queryParams)
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.queryParams.projectTypeId = undefined
      this.queryParams.queryTime = undefined
      this.queryParams.userId = undefined
      this.$emit('submit', this.queryParams)
    },
  },
}
</script>
